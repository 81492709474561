import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { Helmet } from 'react-helmet-async';
import styled from 'styled-components';

import { routes } from '@routes';

import { useAuthContext } from '@providers/AuthProvider';

import { useSlugRedirect } from '@hooks/useSlugRedirect';

import { LinearProgressLoader } from '@components/spinners/LinearProgressLoader';
import { Header } from '@components/Header';
import { Add } from '@components/icons/Add';
import { Button } from '@components/form-elements/buttons/Button';

import { useDownloadCSV } from './hooks/useDownloadCSV';
import { useJobsList } from './hooks/useJobsList';

import { TabSwitcher } from './components/TabSwitcher';
import { StatusFilters } from './components/StatusFilters';
import { JobsTable } from './JobsTable';
import { WSUpdateReceiver } from '@pages/User/JobsListPage/components/WSUpdateReceiver';
import { Popover } from '@components/Popover';
import { Filter } from './components/Filter';
import { Move as MoveIcon } from '@components/icons/Move';
import { TrashBin } from '@components/icons/TrashBin';
import { IconButton } from '@components/form-elements/buttons/IconButton';
import { ModalNamesEnum } from '@constants/enums/ModalNamesEnum';
import { useModalContext } from '@providers/ModalProvider';

const Content = styled.main`
  padding: 8px 30px 50px;
  min-height: calc(100vh - 120px);

  .selected-wrapper {
    display: flex;
    gap: 5px;
    padding: 12px 0 20px;
    align-items: center;
  }
  .selected-tag {
    padding: 4px 6px;
    border-radius: 5px;
    border: 1px solid #d5def2;
    background: #d5def2;
    color: #40608f;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 120%; /* 14.4px */
    height: 22px;
    margin: 0 5px 0 0;
  }
`;

const DeleteButton = styled(IconButton)`
  width: 30px;
  height: 30px;
  align-self: center;

  :hover {
    background: #ff9191;

    path {
      fill: #fff;
      color: #fff;
    }
  }
`;

const MoveButton = styled(IconButton)`
  width: 30px;
  height: 30px;
  align-self: center;

  :hover {
    background: #cadcf8;

    path {
      stroke: #fff;
      color: #fff;
    }
  }
`;

const StyledButton = styled(Button)`
  width: 130px;
  display: flex;
  grid-gap: 5px;
  align-items: center;
`;

export const JobsListPage = () => {
  const navigate = useNavigate();
  const { openModal, closeModal } = useModalContext();
  const [orderBy, setOrderBy] = useState<string>('createdAt');
  const [orderType, setOrderType] = useState('desc');
  const [statusFilters, setStatusFilters] = useState({
    all: true,
    JOB_STATUS_DRAFT: false,
    JOB_STATUS_PROCESSING: false,
    JOB_STATUS_TRANSCRIBING: false,
    JOB_STATUS_PROOFING: false,
    JOB_STATUS_COMPLETE: false,
    JOB_STATUS_READY_FOR_TRANSCRIBING: false,
    JOB_STATUS_READY_FOR_PROOFREADING: false,
    JOB_STATUS_READY_FOR_FINAL: false,
    JOB_STATUS_FINALIZING: false,
    AS_ONE_LOCKED: false,
  });
  const [dateFilter, setDateFilter] = useState({
    from: undefined,
    to: undefined,
  });
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [search, setSearch] = useState('');
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(25);
  const { workspace, organization } = useAuthContext();
  const defaultTab = ['all', 'assignedAndUnassigned'].includes(organization?.permissions?.viewJobs)
    ? 'All Jobs'
    : 'Assigned To Me';
  const [currentTab, setCurrentTab] = useState<'All Jobs' | 'Assigned To Me'>(defaultTab);
  const [checkedJobs, setCheckedJobs] = useState<string[]>([]);

  useSlugRedirect(routes.jobsList);

  useEffect(() => {
    if (currentTab !== defaultTab) {
      setCurrentTab(defaultTab);
    }
  }, [organization?.id]);

  const {
    jobs,
    isLoading,
    requestData: reload,
    endpoint,
  } = useJobsList({
    currentTab,
    page,
    pageSize,
    orderBy,
    orderType,
    search,
    statusFilters,
    dateFilter,
    setCheckedJobs,
  });
  const { onExportCsv } = useDownloadCSV({ endpoint, totalItemsCount: jobs.count ?? 0, search, dateFilter });

  if (!organization || !workspace) {
    return null;
  }

  const handleAddJob = () => {
    navigate(routes.newJob.make(organization?.slug ?? '', workspace?.slug ?? ''));
  };

  const showMoveJobsToWSModal = () => {
    openModal(ModalNamesEnum.MoveJobsToWSModal, {
      jobIds: checkedJobs,
      onSuccess: () => {
        reload();
        closeModal();
      },
    });
  };

  const showRemoveJobsModal = () => {
    openModal(ModalNamesEnum.DeleteJobsModal, {
      jobIds: checkedJobs,
      onSuccess: () => {
        reload();
        closeModal();
      },
    });
  };

  const headerLeftSideContent = [];
  if (organization?.permissions?.submitJobs) {
    headerLeftSideContent.push(
      <StyledButton key="button" data-testid="create_job_header_button" size="small" onClick={handleAddJob}>
        <Add height="12px" />
        Create New Job
      </StyledButton>,
    );
  }
  headerLeftSideContent.push(<TabSwitcher key="TabSwitcher" currentTab={currentTab} setCurrentTab={setCurrentTab} />);

  const FilterPopover = () => {
    return (
      <Popover
        styles="margin-top: 40px; margin-left: -100px;"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
      >
        <Filter filter={dateFilter} setFilter={setDateFilter} close={() => setAnchorEl(null)} />
      </Popover>
    );
  };

  return (
    <>
      <Helmet>
        <title>Jobs List - AutoScript</title>
      </Helmet>
      <Header
        setSearch={setSearch}
        search={search}
        buttonLabel={jobs.data?.length ? 'Export CSV' : undefined}
        buttonOnClick={jobs.data?.length ? () => onExportCsv() : undefined}
        leftSideContent={headerLeftSideContent}
        onFilterClick={(e) => setAnchorEl(e.currentTarget)}
        filterCounter={dateFilter.from || dateFilter.to ? 1 : 0}
      />

      <LinearProgressLoader active={isLoading} />
      <Content>
        <div className="flex gap-3 items-center">
          <StatusFilters statusFilters={statusFilters} setStatusFilters={setStatusFilters} />
          {checkedJobs.length > 0 && (
            <>
              <svg
                style={{ marginTop: '-8px' }}
                width="2"
                height="35"
                viewBox="0 0 2 35"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M1 0V35" stroke="#D5DEF2" />
              </svg>
              <div className="selected-wrapper">
                <div className="selected-tag">{checkedJobs.length} selected</div>

                {organization?.permissions?.submitJobs ? (
                  <MoveButton title="Move to another Workspace" onClick={() => showMoveJobsToWSModal()}>
                    <MoveIcon />
                  </MoveButton>
                ) : null}
                {organization?.permissions?.editJobs ? (
                  <DeleteButton title="Remove" onClick={() => showRemoveJobsModal()}>
                    <TrashBin />
                  </DeleteButton>
                ) : null}
              </div>
            </>
          )}
        </div>

        <JobsTable
          jobs={jobs}
          page={page}
          setPage={setPage}
          pageSize={pageSize}
          setPageSize={setPageSize}
          orderBy={orderBy}
          setOrderBy={setOrderBy}
          orderType={orderType}
          setOrderType={setOrderType}
          reload={reload}
          search={search}
          setSearch={setSearch}
          checkedJobs={checkedJobs}
          setCheckedJobs={setCheckedJobs}
        />
        <WSUpdateReceiver jobs={jobs} reload={reload} />
      </Content>

      <FilterPopover />
    </>
  );
};
