import React, { useState } from 'react';

import { Error } from '@components/Error';
import { validatePassword } from '@helpers/validatePassword';
import { accountTypes } from '@constants/accountTypes';
import { Input } from '@components/form-elements/Input';
import { Button } from '@components/form-elements/buttons/Button';
import { Spinner } from '@components/spinners/Spinner';

import { UserIcon } from '@components/icons/User';
import { EmailIcon } from '@components/icons/Email';
import { EyeIcon } from '@components/icons/Eye';
import { EyeClosedIcon } from '@components/icons/EyeClosed';
import { Case } from '@components/icons/Case';
import { CheckBox } from '@components/form-elements/CheckBox';
import { checkEmailDomain } from '@queries/authentication/checkEmailDomain';

import { StyledSelect } from '../../styles/StyledSelect';
import { AgreementModal } from '../../components/AgreementModal';
import { usePageContext } from '../../../../providers/PageProvider';

export const RegistrationPageFirstStage = () => {
  const {
    setStage,
    isLoading,
    email,
    fromEmail,
    paramsEmail,
    name,
    setName,
    agreement,
    setAgreement,
    setEmail,
    password,
    setPassword,
    confirmation,
    setConfirmation,
    lastName,
    profile,
    setLastName,
    setProfile,
    company,
    setCompany,
    error,
    setError,
    goToLogin,
  } = usePageContext();
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [emailError, setEmailError] = useState<boolean>(false);
  const [isAgreementModalOpen, setIsAgreementModalOpen] = useState<boolean>(false);
  const [emailIsSSOError, setEmailIsSSOError] = useState<boolean>(false);

  const emailIsSSO = async () => {
    const emailDomain = email.split('@')[1];
    const result = await checkEmailDomain(emailDomain);

    if (result?.ssoRedirectUrl) {
      setError('err.ssoProvider');
      setEmailIsSSOError(true);
      setEmailError(true);
      return true;
    } else {
      setError('');
      setEmailIsSSOError(false);
    }

    return false;
  };

  const isEmailInvalid = () => {
    const isInternalStands =
      window.location.hostname === 'staging.autoscript.ai' || window.location.hostname === 'demo.autoscript.ai';

    if (isInternalStands && !fromEmail) {
      const isEmailIncludesVoiceScript =
        email.toLowerCase().endsWith('@voicescript.ai') || email.toLowerCase().endsWith('@mailslurp.com');
      setEmailError(!isEmailIncludesVoiceScript);
      if (!isEmailIncludesVoiceScript) {
        setError('err.voicescriptEmail');
      }
      return !isEmailIncludesVoiceScript;
    }

    return false;
  };

  const handleFormSubmit = async (evt: React.FormEvent<HTMLFormElement>) => {
    setError('');
    evt.preventDefault();

    const emailIsSSOLogin = await emailIsSSO();

    if (isEmailInvalid() || emailIsSSOLogin) {
      return false;
    }

    const error = validatePassword(password, confirmation);
    if (error) {
      setError(error);
      return false;
    }

    setStage('signupSurvey');
  };

  const accountTypeOptions = Object.entries(accountTypes).map(([key, value]) => {
    return { value: key, label: value };
  });

  const isButtonDisabled =
    isLoading ||
    !name ||
    !lastName ||
    !email ||
    !password ||
    !profile ||
    !confirmation ||
    password !== confirmation ||
    !agreement ||
    emailIsSSOError;

  return (
    <>
      {isLoading && <Spinner overlay={true} />}
      <div className="root">
        <h1 className="createNewAccount">Create a New Account</h1>
        {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
        {/* @ts-ignore */}
        <form onSubmit={handleFormSubmit}>
          <div className="margin20">
            <Input
              onChange={(e) => {
                setName(e.target.value);
                setError('');
              }}
              name="name"
              label="First name"
              type="text"
              value={name || ''}
              required={true}
              icon={UserIcon}
            />
          </div>

          <div className="margin20">
            <Input
              onChange={(e) => {
                setLastName(e.target.value);
                setError('');
              }}
              name="lastName"
              label="Last name"
              type="text"
              value={lastName || ''}
              required={true}
              icon={UserIcon}
            />
          </div>

          <div className="margin20">
            <Input
              onChange={(e) => {
                setEmail(e.target.value);
                setError('');
              }}
              name="email"
              label="Email"
              type="email"
              value={email || ''}
              disabled={!!(paramsEmail && email)}
              required={true}
              error={emailError}
              icon={EmailIcon}
            />
          </div>

          <div className="margin20">
            <Input
              onChange={(e) => {
                setPassword(e.target.value);
                setError('');
              }}
              onIconClick={() => setIsPasswordVisible(!isPasswordVisible)}
              name="password"
              label="Password"
              type={isPasswordVisible ? 'text' : 'password'}
              value={password || ''}
              required={true}
              icon={isPasswordVisible ? EyeIcon : EyeClosedIcon}
            />
          </div>
          <div className="margin20">
            <Input
              onChange={(e) => {
                setConfirmation(e.target.value);
                setError('');
              }}
              onIconClick={() => setIsPasswordVisible(!isPasswordVisible)}
              name="confirmation"
              label="Confirm password"
              error={!!(confirmation && confirmation !== password)}
              type={isPasswordVisible ? 'text' : 'password'}
              value={confirmation || ''}
              required={true}
              icon={isPasswordVisible ? EyeIcon : EyeClosedIcon}
            />
          </div>

          <div className="margin20">
            <StyledSelect
              name="profile"
              label="Account Type"
              value={profile ?? ''}
              onChange={(name, value) => {
                setProfile(value);
                setError('');
              }}
              options={accountTypeOptions}
              required={true}
              hideSelectedOption={true}
            />
          </div>

          <div className="margin20">
            <Input
              onChange={(e) => {
                setCompany(e.target.value);
                setError('');
              }}
              name="company"
              label="Company name"
              value={company || ''}
              icon={Case}
            />
          </div>

          <div className="margin30 checkboxWrapper">
            <CheckBox
              checked={!!agreement}
              onChange={(e) => {
                setAgreement(e);
              }}
            />
            I agree to the &nbsp;
            <a onClick={() => setIsAgreementModalOpen(true)}>Terms of Service</a> &nbsp; and &nbsp;
            <a href="https://voicescript.ai/privacy-policy/" target="_blank" rel="noreferrer">
              Privacy Policy
            </a>
          </div>

          {error ? (
            <div className="formError" data-testid="errorMessage">
              <Error error={error} />
            </div>
          ) : null}

          <p className="haveAnAccount">
            Have an account?&nbsp;&nbsp;
            <span onClick={goToLogin} className="signIn">
              Sign In
            </span>
          </p>

          <Button size="medium" disabled={isButtonDisabled} className="margin30" type="submit">
            Next
          </Button>
        </form>
      </div>

      <AgreementModal isOpen={isAgreementModalOpen} setIsOpen={setIsAgreementModalOpen} />
    </>
  );
};
