import styled from 'styled-components';

import { UserCard } from '../../../UserCard';
import { UserPaymentModelStatus } from '../../../UserPaymentModelStatus';
import { OrganizationList } from '../../../OrganizationList';

const UserPaymentWrapper = styled('div')`
  padding: 0 0 15px;
`;

export const Welcome = () => {
  return (
    <div className="w-full">
      <div className="border-geyser border-t w-11/12 m-auto h-px pb-2" />
      <OrganizationList />

      <UserPaymentWrapper>
        <UserCard />
        <UserPaymentModelStatus />
      </UserPaymentWrapper>
    </div>
  );
};
