import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

import { routes } from '@routes';

import { useAuthContext } from '@providers/AuthProvider';
import { useAPI } from '@hooks/useAPI';
import { getAmericanTime } from '@helpers/getAmericanTimezone';
import { downloadCSV } from '@helpers/downloadCSV';

import { TRole, TTeamMemberItem } from 'app/types/entities/TRole';
import { TPaginatedResponse } from 'app/types/API/TPaginatedResponse';
import { TUser } from 'app/types/entities/TUser';

import { Header } from '@components/Header';
import { Button } from '@components/form-elements/buttons/Button';
import { LinearProgressLoader } from '@components/spinners/LinearProgressLoader';
import { CustomTable } from '@components/Table';
import { PagePagination } from '@components/Pagination';
import { DiagonalCross } from '@components/icons/DiagonalCross';
import { SortableHead } from '@components/Table/SortableHead';
import { SortingDirection } from '@components/Table/SortingDirection';

import { StyledSwitchButtonWrapper } from '@pages/User/MembersPage/styles/StyledSwitchButtonWrapper';
import { CellContent } from './styles/CellContent';
import { FlexCellContent } from './styles/FlexCellContent';
import { CellNoEllipsisContent } from './styles/CellNoEllipsisContent';
import { Avatar } from './styles/Avatar';
import { AvatarName } from './styles/AvatarName';
import { StyledLink } from './styles/StyledLink';
import { TeamsCell } from './styles/TeamsCell';
import { ArrowIcon } from '@pages/User/MemberReportsPage/icons/Arrow';
import { InlineJobsTable } from '@pages/User/MemberReportsPage/components/InlineJobsTable';
import { Filter } from '@pages/User/MemberReportsPage/components/Filter';
import { OrganizationsService, TeamsService } from 'app/API';

const MainContent = styled.main`
  padding: 8px 30px 50px;
  min-height: calc(100vh - 120px);

  td {
    vertical-align: top;
  }
`;

export const MemberReportsPage = () => {
  const { call } = useAPI();
  const navigate = useNavigate();
  const { me, organization } = useAuthContext();
  const [isLoading, setIsLoading] = useState(false);
  const [openedTableId, setOpenedTableId] = useState<string | null>(null);
  const [users, setUsers] = useState<TPaginatedResponse<TTeamMemberItem>>({
    data: [],
    count: 0,
  });
  const [sortingField, setSortingField] = useState('createdAt');
  const [sortingReverse, setSortingReverse] = useState(true);
  const [search, setSearch] = useState('');
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(25);
  const [filter, setFilter] = useState<any>({
    from: undefined,
    to: undefined,
  });

  useEffect(() => {
    reloadPage();
  }, [organization, page, pageSize, sortingField, sortingReverse, search, filter]);

  const reloadPage = async () => {
    setIsLoading(true);
    setOpenedTableId(null);
    const users = await call(
      OrganizationsService.getOrgMemberReports({
        oid: organization?.id ?? '',
        pageNo: page.toString(),
        pageSize: pageSize.toString(),
        orderBy: sortingField,
        orderType: sortingReverse ? 'desc' : 'asc',
        search,
        jobsFrom: filter.from ? filter.from.getTime() : '',
        jobsTo: filter.to ? filter.to.getTime() : '',
      }),
    );
    setUsers(users);
    setIsLoading(false);
  };

  const toggleSort = (field: string) => {
    if (sortingField !== field) {
      setSortingField(field);
      setSortingReverse(false);
    } else {
      setSortingReverse((prevState) => !prevState);
    }
  };

  const onDeleteUserTeam = async (team: TRole, userId: string) => {
    await call(TeamsService.removeTeamFromUser({ oid: organization?.id ?? '', userId, id: team.id }));
    reloadPage();
  };

  const onExportCsv = async () => {
    const usersForExport = await call(
      OrganizationsService.getOrgMemberReports({
        oid: organization?.id ?? '',
        pageNo: '1',
        pageSize: users.count.toString(),
        orderBy: sortingField,
        orderType: sortingReverse ? 'desc' : 'asc',
        search,
        jobsFrom: filter.from ? filter.from.getTime() : '',
        jobsTo: filter.to ? filter.to.getTime() : '',
      }),
    );
    const headers = ['ID', 'Name', 'Email', 'Phone', 'Teams', 'Active Jobs', 'Jobs Completed', 'Edits Per Page'];
    const array = usersForExport.data.map((data: any) => {
      return {
        id: data.id,
        name: `${data?.name} ${data?.lastname}`,
        email: data.email,
        phone: data.phone,
        teams: data.teams
          .filter((team: TRole) => team.organizationId === organization?.id)
          .map((team: TRole) => team.name)
          .join(' '),
        activeJobs: data.activeJobs,
        completedJobs: data.completedJobs,
        editsPerPage: data.totalPages ? ((data.totalEdits || 0) / (data.totalPages || 0)).toFixed(2) : '-',
      };
    });
    return downloadCSV(array, headers);
  };

  const tableHead = [
    {
      headComponent: () => <td style={{ width: '1%' }}></td>,
      render: (data: TUser) => {
        const avatarName = `${data?.name[0]}${data?.lastname[0]}`;
        return (
          <CellContent>
            {data?.avatar ? <Avatar src={data.avatar} alt="" /> : <AvatarName>{avatarName}</AvatarName>}
          </CellContent>
        );
      },
    },
    {
      headComponent: () => <td style={{ width: '20%' }}>Name</td>,
      render: (data: TUser) => (
        <CellContent>
          {data.id === me.id ? (
            <StyledLink to={routes.accountPage.make()}>
              {data.name} {data.lastname}
            </StyledLink>
          ) : (
            <StyledLink to={routes.userDetails.make(organization?.slug ?? '', data.id)}>
              {data.name} {data.lastname}
            </StyledLink>
          )}
        </CellContent>
      ),
    },
    {
      headComponent: () => <td style={{ width: '50%' }}>Contacts</td>,
      render: (data: TUser) => (
        <CellContent>
          <span style={{ display: 'block' }}>{data.email}</span>
          <span style={{ display: 'block' }}>{data.phone}</span>
        </CellContent>
      ),
    },
    {
      headComponent: () => <td style={{ width: '1%' }}>Created</td>,
      render: (data: TUser) => <CellContent>{getAmericanTime(data.createdAt)}</CellContent>,
    },
    {
      headComponent: () => <td style={{ width: '50%' }}>Teams</td>,
      render: (data: TUser) => {
        const teams = (data.teams ?? []).filter((team) => team.organizationId === organization?.id);
        return (
          <CellContent>
            <CellNoEllipsisContent>
              {(teams || []).map((item: TRole) => (
                <TeamsCell key={item.name} color={item.colour}>
                  {item.name}
                  {teams.length > 1 && <DiagonalCross onClick={() => onDeleteUserTeam(item, data.id)} />}
                </TeamsCell>
              ))}
            </CellNoEllipsisContent>
          </CellContent>
        );
      },
    },
    {
      headComponent: () => (
        <SortableHead style={{ width: '1%' }} onClick={() => toggleSort('activeJobs')}>
          Active Jobs
          {sortingField === 'activeJobs' && <SortingDirection reverse={sortingReverse} />}
        </SortableHead>
      ),
      render: (data: TUser) => <FlexCellContent>{data.activeJobs}</FlexCellContent>,
    },
    {
      headComponent: () => (
        <SortableHead style={{ width: '1%' }} onClick={() => toggleSort('completedJobs')}>
          Jobs Completed
          {sortingField === 'completedJobs' && <SortingDirection reverse={sortingReverse} />}
        </SortableHead>
      ),
      render: (data: TUser) => <FlexCellContent>{data.completedJobs}</FlexCellContent>,
    },
    {
      headComponent: () => (
        <SortableHead style={{ width: '1%' }} onClick={() => toggleSort('editsPerPage')}>
          Edits Per Page
          {sortingField === 'editsPerPage' && <SortingDirection reverse={sortingReverse} />}
        </SortableHead>
      ),
      render: (data: TUser) => (
        <FlexCellContent>
          {data.totalPages ? ((data.totalEdits || 0) / (data.totalPages || 0)).toFixed(2) : '-'}
        </FlexCellContent>
      ),
    },
    {
      headComponent: () => <td></td>,
      render: (data: TUser) => (
        <FlexCellContent>
          {data.checkoutEvents?.length ? (
            <ArrowIcon
              onClick={() => setOpenedTableId(openedTableId === data.id ? null : data.id)}
              className={openedTableId === data.id ? 'arrow-icon active' : 'arrow-icon'}
            />
          ) : null}
        </FlexCellContent>
      ),
    },
  ];

  const renderInlineEditRow = (data: TUser) => {
    if (openedTableId !== data.id) {
      return null;
    }
    return <InlineJobsTable data={data} />;
  };

  return (
    <>
      <Helmet>
        <title>Member Reports - AutoScript</title>
      </Helmet>
      <Header
        leftSideContent={[
          <StyledSwitchButtonWrapper key="tabSwitcher">
            <Button
              onClick={() => {
                navigate(routes.organizationMembers.make(organization?.slug ?? ''));
              }}
              className="button"
            >
              Members
            </Button>
            <Button
              onClick={() => {
                navigate(routes.invitations.make(organization?.slug ?? ''));
              }}
              className="button"
            >
              Invitations
            </Button>
            <Button
              onClick={() => {
                navigate(routes.organizationMemberReports.make(organization?.slug ?? ''));
              }}
              className="button active"
            >
              Reports
            </Button>
          </StyledSwitchButtonWrapper>,
        ]}
        title="Members"
        buttonLabel={users.data?.length ? 'Export CSV' : undefined}
        buttonOnClick={users.data?.length ? () => onExportCsv() : undefined}
        setSearch={setSearch}
        search={search}
      />

      <LinearProgressLoader active={isLoading} />
      <MainContent>
        <Filter setFilter={setFilter} filter={filter} />
        <CustomTable head={tableHead} data={users.data} renderInlineEditRow={renderInlineEditRow} />
        <PagePagination
          totalItemsCount={users.count ?? 0}
          page={page ?? 1}
          setPage={setPage}
          pageSize={pageSize}
          setPageSize={setPageSize}
        />
      </MainContent>
    </>
  );
};
