import { useNavigate } from 'react-router-dom';

import { routes } from '@routes';
import { useAuthContext } from '@providers/AuthProvider';

import { UniversalCard } from '@components/UniversalCard';
import { getShortName } from '@helpers/getShortName';
import { TOrganization } from 'app/types/entities/TOrganization';

type TProps = {
  organization: TOrganization;
};

export const OrganizationCard = ({ organization }: TProps) => {
  const navigate = useNavigate();
  const { setOrganization } = useAuthContext();

  const onClick = () => {
    setOrganization(organization);
    navigate(routes.dashboard.make(organization.slug));
  };

  const onDetailsClick = (organization: TOrganization) => {
    setOrganization(organization);
    navigate(routes.organizationDetail.make(organization.slug));
  };

  const organizationShortname = getShortName(organization.name || organization.slug);

  const actions = [{ label: 'Details', onClick: () => onDetailsClick(organization) }];

  const content = (
    <>
      <a
        onClick={(e) => {
          e.preventDefault();
          onClick();
        }}
        href={routes.dashboard.make(organization.slug)}
        className="font-medium text-queen-blue hover:text-primary"
      >
        {organization.name || organization.slug}
      </a>
      <p>&nbsp;</p>
      <p className="text-primary text-xs">
        {organization.userCount} {organization.userCount === 1 ? 'member' : 'members'}
      </p>
    </>
  );

  return (
    <UniversalCard
      content={content}
      shortName={organizationShortname}
      actions={actions}
      image={organization.image}
      onClick={() => onClick()}
    />
  );
};
