import { createRoot } from 'react-dom/client';
import * as Sentry from '@sentry/react';
import { App } from './app/App';
import { useLocation, useNavigationType, createRoutesFromChildren, matchRoutes } from 'react-router-dom';

import { ErrorBoundaryComponent } from 'app/pages/ErrorBoundary';

import './index.css';
import React from 'react';

const isDevEnvironment = window.location.hostname === 'localhost';
if (!isDevEnvironment) {
  Sentry.init({
    dsn: 'https://3ede02bc0ebf4b93b07b00f1b99502b8@o1135867.ingest.sentry.io/6204281',
    tracesSampleRate: 1.0,
    integrations: [
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect: React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
    ],
  });
}

const container = document.getElementById('root');
if (!container) {
  throw new Error('Root element not found');
}
const root = createRoot(container);

root.render(
  <Sentry.ErrorBoundary
    fallback={({ error, componentStack }) => <ErrorBoundaryComponent error={error} componentStack={componentStack} />}
  >
    <App />
  </Sentry.ErrorBoundary>,
);
