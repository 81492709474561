import { MouseEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Header } from '@components/Header';
import styled from 'styled-components';
import { MainBlock, TForm } from './components/blocks/MainBlock';
import { useNavigate, useParams } from 'react-router';
import { v4 } from 'uuid';
import { Button } from '@components/form-elements/buttons/Button';
import { upsertRepository } from '@queries/repositories/upsertRepository';
import { getAllJobs } from '@queries/repositories/getAllJobs';

import { DisclosureSection } from '@components/DisclosureSection';
import { Add } from '@components/icons/Add';
import { useAuthContext } from '@providers/AuthProvider';
import { JobsTable } from 'app/pages/User/RepositoryPage/components/JobsTable';

import { routes } from '@routes';
import { LinearProgressLoader } from '@components/spinners/LinearProgressLoader';
import { Restricted } from '@providers/PermissionProvider/Restricted';
import { handleChangeStatusHandler, mapAttachmentsToIFileWithMeta, RepositoryAttachment } from './utils';

import { useModalContext } from '@providers/ModalProvider';
import { ModalNamesEnum } from '@constants/enums/ModalNamesEnum';
import { TJob } from 'app/types/entities/TJob';
import { TFileWithMeta } from '@components/FileUpload/components/dropzone/types/TFileWithMeta';
import { TPaginatedResponse } from 'app/types/API/TPaginatedResponse';
import { EPermission } from 'app/types/enums/EPermission';
import { EACCEPTED_DOCUMENT_FORMATS } from 'app/types/enums/EACCEPTED_DOCUMENT_FORMATS';
import { EACCEPTED_AUDIO_FORMATS } from 'app/types/enums/EACCEPTED_AUDIO_FORMATS';
import { useAPI } from '@hooks/useAPI';
import { RepositoriesService } from 'app/API';

const Content = styled.main`
  padding: 8px 30px 50px;
  min-height: calc(100vh - 120px);
`;

const RootWrapperDiv = styled('div')`
  padding: 30px 30px 0;
  font-family: 'General Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;

  .uploadDescription {
    color: #575757;
  }

  .multipleFilesHandling {
    .description {
      padding: 10px 0 0;
      color: #878787;
    }
  }

  ul {
    margin: 0;
    color: #878787;
    padding: 0 0 0 18px;
  }

  .errorMessage {
    font-weight: 500;
    font-size: 10px;
    line-height: 100%;
    color: #ff2f2f;
    height: 12px;
  }
`;

const StyledButton = styled(Button)`
  display: flex;
  grid-gap: 5px;
  align-items: center;
  text-align: center;
`;

export const RepositoryPage = () => {
  const { call } = useAPI();
  const [form, setForm] = useState<TForm>({
    name: '',
    slug: '',
    notes: '',
    audioFiles: [],
    documentFiles: [],
    assignee: null,
  });

  const [slugIsAvailable, setSlugIsAvailable] = useState<boolean>(true);

  const [jobs, setJobs] = useState<TPaginatedResponse<TJob>>({
    data: [],
    count: 0,
  });

  const params = useParams<{ repoSlug: string; orgSlug: string }>();
  const navigate = useNavigate();

  const { organization, workspace } = useAuthContext();

  const isNew = !params.repoSlug;

  const newRepoId = useMemo(() => v4(), [v4()]);

  const [associatedJobsDisclosureOpen, setAssociatedJobsDisclosureOpen] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isFilesLoading, setIsFilesLoading] = useState(true);

  const [audioFilesFromServer, setAudioFilesFromServer] = useState<TFileWithMeta[]>([]);
  const [newAudioFiles, setNewAudioFiles] = useState<TFileWithMeta[]>([]);
  const [isAllAudioFilesLoaded, setIsAllAudioFilesLoaded] = useState(true);
  const [audioFilesCount, setAudioFilesCount] = useState(0);
  const [allAudioFiles, setAllAudioFiles] = useState<any[]>([]);

  const [documentFilesFromServer, setDocumentFilesFromServer] = useState<TFileWithMeta[]>([]);
  const [newDocumentFiles, setNewDocumentFiles] = useState<TFileWithMeta[]>([]);
  const [isAllDocumentFilesLoaded, setIsAllDocumentFilesLoaded] = useState(true);
  const [documentFilesCount, setDocumentFilesCount] = useState(0);
  const [allDocumentFiles, setAllDocumentFiles] = useState<any[]>([]);

  const audioFilesCountRef = useRef(audioFilesCount);
  const documentFilesCountRef = useRef(documentFilesCount);
  const [repositoryId, setRepositoryId] = useState<string | null>(isNew ? newRepoId : null);

  const { openModal, closeModal } = useModalContext();

  useEffect(() => {
    if (isNew) {
      setIsLoading(false);
      setIsFilesLoading(false);
      return;
    }
    call(RepositoriesService.findOneBySlug({ orgSlug: params.orgSlug ?? '', slug: params.repoSlug })).then((res) => {
      const audioFilesFromServer = res.Attachments
        ? res.Attachments.filter((file: RepositoryAttachment) =>
            EACCEPTED_AUDIO_FORMATS.includes('.' + file.fileName.split('.').pop()?.toLowerCase()),
          )
        : [];

      const documentFilesFromServer = res.Attachments
        ? res.Attachments.filter((file: RepositoryAttachment) =>
            EACCEPTED_DOCUMENT_FORMATS.includes('.' + file.fileName.split('.').pop()?.toLowerCase()),
          )
        : [];

      setForm({
        name: res.name,
        slug: res.slug,
        notes: res.notes,
        assignee: res.Assignee,
        audioFiles: audioFilesFromServer,
        documentFiles: documentFilesFromServer,
      });

      setRepositoryId(res.id);
      if (audioFilesFromServer) {
        setAudioFilesFromServer(mapAttachmentsToIFileWithMeta(audioFilesFromServer as any[]));
        setAllAudioFiles(mapAttachmentsToIFileWithMeta(audioFilesFromServer as any[]));
      }

      if (documentFilesFromServer) {
        setDocumentFilesFromServer(mapAttachmentsToIFileWithMeta(documentFilesFromServer as any[]));
        setAllDocumentFiles(mapAttachmentsToIFileWithMeta(documentFilesFromServer as any[]));
      }

      setIsLoading(false);
      setIsFilesLoading(false);
    });
  }, [params.repoSlug]);

  useEffect(() => {
    const removedFilter = (file: TFileWithMeta) => file.meta.status !== 'removed';
    setAllAudioFiles(
      [...audioFilesFromServer.filter(removedFilter), ...newAudioFiles.filter(removedFilter)].map((f, idx) => {
        return { ...f, weight: idx };
      }),
    );
  }, [audioFilesFromServer, newAudioFiles]);

  useEffect(() => {
    const removedFilter = (file: TFileWithMeta) => file.meta.status !== 'removed';
    setAllDocumentFiles(
      [...documentFilesFromServer.filter(removedFilter), ...newDocumentFiles.filter(removedFilter)].map((f, idx) => {
        return { ...f, weight: idx };
      }),
    );
  }, [documentFilesFromServer, newDocumentFiles]);

  const submit = (event: MouseEvent) => {
    event.preventDefault();

    if (!repositoryId || !form.name || !form.slug) {
      return;
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { audioFiles, documentFiles, ...rest } = form;

    setIsSubmitting(true);
    upsertRepository({
      ...rest,
      notes: rest.notes ?? '',
      id: repositoryId,
      organizationSlug: params.orgSlug,
    })
      .then(() => {
        setIsSubmitting(false);
        setNewAudioFiles([]);
        setNewDocumentFiles([]);
        navigate(routes.repositoriesList.make(params.orgSlug));
      })
      .catch((err) => {
        console.log('err', err);
        console.error(err.message);
        setIsSubmitting(false);
        navigate(routes.repositoriesList.make(params.orgSlug));
      });
  };

  useEffect(() => {
    const removedFilter = (file: TFileWithMeta) => file.meta.status !== 'removed';
    setAllAudioFiles(
      [...audioFilesFromServer.filter(removedFilter), ...newAudioFiles.filter(removedFilter)].map((f, idx) => {
        return { ...f, weight: idx };
      }),
    );
  }, [audioFilesFromServer, newAudioFiles]);

  useEffect(() => {
    const removedFilter = (file: TFileWithMeta) => file.meta.status !== 'removed';
    setAllDocumentFiles(
      [...documentFilesFromServer.filter(removedFilter), ...newDocumentFiles.filter(removedFilter)].map((f, idx) => {
        return { ...f, weight: idx };
      }),
    );
  }, [documentFilesFromServer, newDocumentFiles]);

  const handleChangeAudioFileStatus = useCallback(
    handleChangeStatusHandler(
      params.orgSlug ?? '',
      repositoryId,
      allAudioFiles,
      setIsAllAudioFilesLoaded,
      audioFilesCountRef,
      setAudioFilesCount,
      newAudioFiles,
      setNewAudioFiles,
      call,
    ),
    [allAudioFiles, audioFilesCountRef.current],
  );

  const handleChangeDocumentFileStatus = useCallback(
    handleChangeStatusHandler(
      params.orgSlug ?? '',
      repositoryId,
      allDocumentFiles,
      setIsAllDocumentFilesLoaded,
      documentFilesCountRef,
      setDocumentFilesCount,
      newDocumentFiles,
      setNewDocumentFiles,
      call,
    ),
    [allDocumentFiles, documentFilesCountRef.current],
  );

  const handleBulkSubmitModal = () => {
    openModal(ModalNamesEnum.BulkJobCreationModal, {
      repositoryId,
      repository: form,
      audioFiles: allAudioFiles,
      organizationId: organization?.id,
      onSuccess: () => {
        closeModal();
      },
    });
  };

  return (
    <>
      <Helmet>
        <title>Create a new Case - AutoScript</title>
      </Helmet>
      <Header title={isNew ? 'Create Case' : form.name} backRoute={routes.repositoriesList.make(params.orgSlug)} />

      <LinearProgressLoader active={isLoading || isSubmitting} />
      <Content>
        <form className="flex flex-col">
          <RootWrapperDiv>
            {repositoryId ? (
              <MainBlock
                repositoryId={repositoryId}
                audioFiles={allAudioFiles}
                setAudioFiles={setAllAudioFiles}
                newAudioFiles={newAudioFiles}
                setNewAudioFiles={setNewAudioFiles}
                audioFilesFromServer={audioFilesFromServer}
                setAudioFilesFromServer={setAudioFilesFromServer}
                documentFiles={allDocumentFiles}
                setDocumentFiles={setAllDocumentFiles}
                documentFilesFromServer={documentFilesFromServer}
                setDocumentFilesFromServer={setDocumentFilesFromServer}
                newDocumentFiles={newDocumentFiles}
                setNewDocumentFiles={setNewDocumentFiles}
                handleChangeAudioStatus={handleChangeAudioFileStatus}
                handleChangeDocumentStatus={handleChangeDocumentFileStatus}
                isFilesLoading={isFilesLoading}
                form={form}
                setForm={setForm}
                slugIsAvailable={slugIsAvailable}
                setSlugIsAvailable={setSlugIsAvailable}
                isNew={isNew}
              />
            ) : null}
          </RootWrapperDiv>
          <div className="flex flex-col">
            <div className="grid place-items-end">
              <div className="w-36">
                <Button
                  className="my-2"
                  disabled={isSubmitting || !isAllAudioFilesLoaded || !isAllDocumentFilesLoaded || !slugIsAvailable}
                  variant="bold"
                  onClick={submit}
                >
                  {isNew ? 'Create' : 'Update'}
                </Button>
              </div>
            </div>
          </div>
        </form>
        <Restricted to={EPermission.editRepositories}>
          <>
            {!isNew ? (
              <DisclosureSection
                open={associatedJobsDisclosureOpen}
                setOpenState={setAssociatedJobsDisclosureOpen}
                loading={false}
                title={`Associated Jobs (${jobs?.data?.length || 0})`}
              >
                <div className="flex flex-col">
                  <div className="grid place-items-end">
                    <div className="w-70 flex flex-row">
                      <div className="mr-2 text-center">
                        <StyledButton variant="outlined" onClick={handleBulkSubmitModal}>
                          Bulk Create Jobs
                        </StyledButton>
                      </div>
                      <div className="w-36">
                        <StyledButton
                          variant="contained"
                          onClick={() =>
                            organization &&
                            workspace &&
                            navigate(routes.newJob.make(organization.slug, workspace.slug) + `?repo=${form.slug}`)
                          }
                        >
                          <Add className="pl-1 mr-1" height="12px" />
                          Create New Job
                        </StyledButton>
                      </div>
                    </div>
                  </div>

                  {repositoryId && !isNew ? (
                    <JobsTable
                      setLoading={setIsLoading}
                      jobs={jobs}
                      setJobs={setJobs}
                      loadJobs={getAllJobs}
                      search=""
                      setSearch={() => {}}
                      currentTab="Repositories Detail"
                      entityId={repositoryId}
                    />
                  ) : null}
                </div>
              </DisclosureSection>
            ) : null}
          </>
        </Restricted>
      </Content>
    </>
  );
};
