import { useState } from 'react';
import styled from 'styled-components';
import { BarElement, CategoryScale, Chart as ChartJS, LinearScale } from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { Button } from '@components/form-elements/buttons/Button';

ChartJS.register(CategoryScale, LinearScale, BarElement);
ChartJS.defaults.color = '#858dbd';

const Wrapper = styled.div`
  .periods {
    position: absolute;
    top: 20px;
    right: 20px;
    width: 200px;
  }
`;

const ChartWrapper = styled.div`
  height: 130px;
  padding: 30px 0 0;
`;
const StyledSwitchButtonWrapper = styled.div`
  display: flex;

  .button {
    border-radius: 0;
    background: #f8fafb;
    color: #40608f;
    font-weight: 500;
    font-size: 12px;
    line-height: 130%;

    &:first-child {
      border-radius: 5px 0 0 5px;
    }

    &:last-child {
      border-radius: 0 5px 5px 0;
    }
  }

  .active {
    background: #d5def2;
  }
`;

type DataItem = {
  date: string;
  value: number;
};

type TProps = {
  data: {
    week?: DataItem[];
    month?: DataItem[];
    totalJobs: number;
  };
};

export const DailyActivity = ({ data }: TProps) => {
  const [period, setPeriod] = useState<'7days' | '30days'>('7days');

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    barThickness: 20,
    borderRadius: 2,
    animation: {
      duration: 0,
    },
    scales: {
      x: {
        beginAtZero: true,
        grid: {
          display: false,
        },
        border: {
          display: false,
        },
      },
      y: {
        beginAtZero: true,
        grid: {
          color: '#d5def2',
          tickBorderDash: [2, 4],
        },
        border: {
          display: false,
          dash: [2, 4],
        },
      },
    },
  };

  const weekDays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
  const periodData = period === '7days' ? data.week || [] : data.month || [];
  const labels = periodData.map((item) => weekDays[new Date(item.date).getDay()]);

  const chartData = {
    labels,
    datasets: [
      {
        data: periodData.map((item) => item.value),
        backgroundColor: '#CADCF8',
      },
    ],
  };

  return (
    <>
      <Wrapper>
        <div className="periods">
          <StyledSwitchButtonWrapper key="tabSwitcher">
            <Button onClick={() => setPeriod('7days')} className={`button ${period === '7days' ? 'active' : ''}`}>
              Last 7 days
            </Button>
            <Button onClick={() => setPeriod('30days')} className={`button ${period === '30days' ? 'active' : ''}`}>
              Last 30 days
            </Button>
          </StyledSwitchButtonWrapper>
        </div>
        <ChartWrapper>
          <Bar options={options} data={chartData} />
        </ChartWrapper>
      </Wrapper>
    </>
  );
};
