import { useAuthContext } from '@providers/AuthProvider';

import { getAmericanTime } from '@helpers/getAmericanTimezone';
import { convertDuration } from '@helpers/convertDuration';
import { downloadCSV } from '@helpers/downloadCSV';

import { JOB_STATUSES_ENUM } from '@constants/enums/jobStatuses';
import { TJob } from 'app/types/entities/TJob';
import { TPaginatedResponse } from 'app/types/API/TPaginatedResponse';

type TProps = {
  totalItemsCount: number;
  dateFilter: {
    from?: Date | null;
    to?: Date | null;
  };
  endpoint: (
    page: number,
    pageSize: number,
    orderBy: string,
    orderType: string,
    search: string,
    workspaceId: string,
    statusFilters: string[],
    dateFilter: {
      from?: Date | null;
      to?: Date | null;
    },
  ) => Promise<TPaginatedResponse<TJob>>;
  search: string;
};

export const useDownloadCSV = ({ totalItemsCount, endpoint, search, dateFilter }: TProps) => {
  const { organization, workspace } = useAuthContext();

  const onExportCsv = async () => {
    const data = await endpoint(1, totalItemsCount, 'createdAt', 'desc', search, workspace?.id ?? '', [], dateFilter);
    const headers = [
      'ID',
      'Title',
      'Due',
      'Assignee',
      'Organization',
      'Workspace',
      'Status',
      'Created',
      'Tags',
      'Duration',
      'Edits %',
    ];
    const array = data.data.map((data: TJob) => {
      const assignee = data?.assignee || data?.draftAssignee;
      return {
        id: data.id,
        title: data.name,
        due: data.deadline ? getAmericanTime(data.deadline, false) : '',
        assignee: assignee ? `${assignee.name} ${assignee.lastname} (${assignee.email})` : '',
        organization: organization?.name ?? '',
        workspace: workspace?.name ?? '',
        status: JOB_STATUSES_ENUM[data.status as keyof typeof JOB_STATUSES_ENUM],
        created: getAmericanTime(data.createdAt),
        tags: (data.tags ?? []).join(', '),
        duration: convertDuration(data.duration),
        editsPercent: Math.round(data.editingProgress || 0),
      };
    });
    return downloadCSV(array, headers);
  };

  return { onExportCsv };
};
