import React from 'react';
import { useNavigate } from 'react-router';

import { routes } from '@routes';

import { useAuthContext } from '@providers/AuthProvider';
import { Meatballs } from '@components/icons/Meatballs';
import { WorkspaceIcon, WorkspaceIconVariant } from '@components/WorkspaceIcon/WorkspaceIcon';

import { WorkspaceListItem } from './styles/WorkspaceListItem';
import { TProps } from './types/TProps';
import { TWorkspace } from 'app/types/entities/TWorkspace';

export const WorkspaceList = ({ closeList }: TProps) => {
  const { me, workspace, organization, setWorkspace } = useAuthContext();

  const navigate = useNavigate();

  const handleWorkspaceClick = (ws: TWorkspace) => {
    setWorkspace(ws);
    navigate(routes.jobsList.make(organization?.slug ?? '', ws.slug));
    closeList();
  };

  const handleWorkspaceDetailsClick = (e: React.MouseEvent<SVGSVGElement>, ws: TWorkspace) => {
    e.stopPropagation();
    e.preventDefault();
    setWorkspace(ws);
    navigate(routes.workspaceDetails.make(organization?.slug ?? '', ws.slug));
    closeList();
  };

  return (
    <div className="w-full h-auto max-h-52 overflow-y-auto absolute z-30 bg-white shadow-xl">
      <ul>
        {me.workspaces &&
          me.workspaces
            .filter((ws: TWorkspace) => {
              const userHasManageWorkspacesPermission = organization?.permissions.manageWorkspaces;
              const userTeamsIncludesWorkspaceTeam = ws.Teams.some((wsTeam) =>
                organization?.userTeams.map((userTeam) => userTeam.name).includes(wsTeam.name),
              );

              return (
                ws.organizationId === organization?.id &&
                ws.id !== workspace?.id &&
                (userTeamsIncludesWorkspaceTeam || userHasManageWorkspacesPermission)
              );
            })
            .map((ws: TWorkspace) => (
              <WorkspaceListItem
                key={ws.id}
                className="cursor-pointer text-primary text-base hover:bg-alice-blue flex flex-row px-2"
              >
                <a
                  className="w-full max-w-48 py-2 text-ellipsis overflow-hidden whitespace-nowrap align-middle font-medium flex"
                  onClick={() => handleWorkspaceClick(ws)}
                  title={ws.name}
                >
                  <WorkspaceIcon
                    name={ws.name}
                    onClick={() => handleWorkspaceClick(ws)}
                    variant={WorkspaceIconVariant.Small}
                  />
                  <span className="workspace-list-workspace-name text-ellipsis overflow-hidden w-36">{ws.name}</span>
                  <Meatballs className="meatballs" onClick={(e) => handleWorkspaceDetailsClick(e, ws)} />
                </a>
              </WorkspaceListItem>
            ))}
      </ul>
    </div>
  );
};
