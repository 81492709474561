import { Link as RouterLink } from 'react-router-dom';
import styled from 'styled-components';

import { useAuthContext } from '@providers/AuthProvider';
import { formatCentsPrice } from '@helpers/formatCentsPrice';
import { routes } from '@routes';
import { GearSvg } from '@components/icons/Gear';
import { useEffect, useState } from 'react';

const BalanceLink = styled(RouterLink)`
  text-align: left;
  color: #858dbd;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 100%; /* 16px */
  letter-spacing: 0.16px;
  display: flex;
  align-items: center;
`;

const Wrapper = styled.div`
  position: relative;
  padding: 0 15px;

  .content {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 8px 0 0;

    .title {
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      color: #313131;
      padding: 0 0 8px;
    }
  }
`;

export const UserPaymentModelStatus = () => {
  const { organization } = useAuthContext();
  const [balance, setBalance] = useState(0);

  useEffect(() => {
    setBalance(organization?.billingAccount?.currentBalance || 0);
  }, [organization]);

  const renderBalanceBlock = () => {
    return (
      <div>
        <div className="title">Credit Balance</div>
        <BalanceLink
          className="no-underline hover:no-underline"
          to={routes.billingAccount.make(organization?.slug ?? '')}
        >
          {formatCentsPrice(balance)}
          <GearSvg style={{ width: '14px', height: '18px', marginLeft: '10px' }} />
        </BalanceLink>
      </div>
    );
  };

  return (
    <Wrapper>{organization?.permissions?.viewBilling && <div className="content">{renderBalanceBlock()}</div>}</Wrapper>
  );
};
