import { Routes } from './components/Routes';
import { PackageUsageProvider } from '@providers/PackageUsageProvider';
import { WebsocketProvider } from '@providers/WebsocketProvider';

export const AuthenticatedRoutes = () => {
  return (
    <>
      <WebsocketProvider>
        <PackageUsageProvider>
          <Routes />
        </PackageUsageProvider>
      </WebsocketProvider>
    </>
  );
};
