import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { routes } from '@routes';

import { TOrganization } from 'app/types/entities/TOrganization';

const EllipsisOrgName = styled.span`
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0;
`;

const OrgIcon = styled.img`
  width: 30px;
  flex: 0 0 auto;
  display: inline-block;
  border-radius: 3px;
  margin: 0 6px 0 2px;
`;

const OrgInitialsIcon = styled.span`
  width: 30px;
  height: 30px;
  display: inline-block;
  flex: 0 0 auto;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 30px;
  text-transform: uppercase;
  border: 1px solid #858dbd;
  border-radius: 5px;
  text-align: center;
  margin: 0 6px 0 2px;
`;

const OrgLink = styled(Link)`
  color: #858dbd;
  border-radius: 5px;
  display: flex;
  width: 100%;
  align-items: center;
  padding: 5px 10px;
  margin: 0;
  font-weight: 500;
  font-size: 12px;
  line-height: 130%;
  position: relative;
  cursor: pointer;
  text-decoration: none;
`;

export type TProps = {
  org: TOrganization;
  handleOrganizationClick: (org: TOrganization) => void;
};

const OrganizationInitials = ({ org }: { org: TOrganization }) => {
  return <OrgInitialsIcon>{org.name[0]}</OrgInitialsIcon>;
};

const OrganizationImage = ({ org }: { org: TOrganization }) => {
  if (!org.image) {
    return <></>;
  }
  return <OrgIcon src={org.image} alt={org.name} />;
};

export const OrganizationRow = ({ org, handleOrganizationClick }: TProps) => {
  return (
    <li className={`h-9 cursor-pointer text-primary text-base hover:bg-alice-blue flex flex-row`}>
      <OrgLink to={routes.dashboard.make(org.slug)} onClick={() => handleOrganizationClick(org)} title={org.name}>
        {org?.image ? <OrganizationImage org={org} /> : <OrganizationInitials org={org} />}
        <EllipsisOrgName className="leading-7">{org.name}</EllipsisOrgName>
      </OrgLink>
    </li>
  );
};
