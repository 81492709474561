import { Link } from 'react-router-dom';

import { routes } from '@routes';
import { useAuthContext } from '@providers/AuthProvider';
import { UniversalCard } from '@components/UniversalCard';
import { getShortName } from '@helpers/getShortName';
import { TRole } from 'app/types/entities/TRole';

type TProps = {
  team: TRole;
  handleDeleteTeam: (team: TRole) => void;
};

export const TeamCard = ({ team, handleDeleteTeam }: TProps) => {
  const { organization } = useAuthContext();

  const actions: any[] = [
    { label: 'Details', link: routes.teamDetails.make(organization?.slug ?? '', team.slug) },
    { label: '', className: 'divider' },
    { label: 'Delete', className: 'red', onClick: () => handleDeleteTeam(team) },
  ];
  if (team.default || team.owner) {
    actions.pop();
    actions.pop();
  }

  const content = (
    <>
      <Link
        to={routes.teamDetails.make(organization?.slug ?? '', team.slug)}
        className="font-medium text-queen-blue hover:text-primary"
      >
        {team.name || team.slug}
      </Link>
      <p>&nbsp;</p>
      {team.members !== undefined ? (
        <p className="text-primary text-xs">
          {team.members} {team.members === 1 ? 'member' : 'members'}
        </p>
      ) : null}
    </>
  );

  const teamShortname = getShortName(team.name || team.slug);
  return <UniversalCard shortNameBGColor={team.colour} content={content} shortName={teamShortname} actions={actions} />;
};
