import styled from 'styled-components';
import { useNavigate } from 'react-router';

import { routes } from '@routes';

import { GearSvg } from '@components/icons/Gear';

import DownIcon from '@components/Table/SortingDirection/down.svg';
import UpIcon from '@components/Table/SortingDirection/up.svg';
import { TOrganization } from 'app/types/entities/TOrganization';

const OrgNameWrapper = styled.div`
  .orgName {
    max-width: 150px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const EllipsisLink = styled.span`
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0;
  max-width: 150px;
`;

const OrgWrapper = styled.div`
  color: #858dbd;
  border-radius: 5px;
  display: flex;
  align-items: center;
  padding: 5px 10px;
  margin: 0;
  font-weight: 500;
  font-size: 12px;
  line-height: 130%;
  position: relative;
  cursor: pointer;
  text-decoration: none;
  height: 48px;
`;

const DropIconHolder = styled.div`
  width: 20px;
  margin: 0 0 0 10px;
  position: absolute;
  right: 10px;
  top: 0;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    width: 8px;
    height: 8px;
    display: block;
  }
`;

const OrgIcon = styled.img`
  width: 30px;
  flex: 0 0 auto;
  display: inline-block;
  border-radius: 3px;
  margin: 0 6px 0 2px;
`;

const OrgInitialsIcon = styled.span`
  width: 30px;
  height: 30px;
  display: inline-block;
  flex: 0 0 auto;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 30px;
  text-transform: uppercase;
  border: 1px solid #858dbd;
  border-radius: 5px;
  text-align: center;
  margin: 0 6px 0 2px;
`;

const DetailsSpan = styled.span`
  display: inline-block;
  height: 16px;
  line-height: 20px;
  margin-right: 4px;
  font-size: 12px;
`;

const OrganizationInitials = ({ org }: { org: TOrganization }) => {
  return <OrgInitialsIcon>{org.name[0]}</OrgInitialsIcon>;
};

const OrganizationImage = ({ org }: { org: TOrganization }) => {
  if (!org.image) {
    return <></>;
  }
  return <OrgIcon src={org.image} alt={org.name} />;
};

export type TProps = {
  organization: TOrganization;
  setOrgListOpen: (value: boolean) => void;
  orgListOpen: boolean;
};

export const CurrentOrganizationRow = ({ setOrgListOpen, orgListOpen, organization }: TProps) => {
  const navigate = useNavigate();

  const nameSection = orgListOpen ? (
    <EllipsisLink>{organization.name}</EllipsisLink>
  ) : (
    <OrgNameWrapper>
      <span onClick={() => setOrgListOpen(!orgListOpen)} className="orgName text-xs font-[600] block">
        {organization.name}
      </span>
      <div className="flex" onClick={() => navigate(routes.organizationDetail.make(organization.slug))}>
        <DetailsSpan>Details</DetailsSpan>
        <GearSvg style={{ width: '14px', height: '18px' }} />
      </div>
    </OrgNameWrapper>
  );

  return (
    <>
      <OrgWrapper onClick={() => orgListOpen && setOrgListOpen(!orgListOpen)}>
        <div onClick={() => setOrgListOpen(!orgListOpen)} className="w-auto flex-shrink-0">
          {organization.image ? <OrganizationImage org={organization} /> : <OrganizationInitials org={organization} />}
        </div>
        {nameSection}

        <DropIconHolder onClick={() => setOrgListOpen(!orgListOpen)}>
          {orgListOpen ? <UpIcon className="w-2" /> : <DownIcon className="w-2" />}
        </DropIconHolder>
      </OrgWrapper>
    </>
  );
};
