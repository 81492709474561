import { client } from 'app/http';
import { TPaginatedResponse } from 'app/types/API/TPaginatedResponse';
import { TJob } from 'app/types/entities/TJob';

export const getWorkspaceJobs = async (
  page = 1,
  pageSize = 25,
  orderBy = 'id',
  orderType = 'asc',
  search = '',
  workspaceId = '',
  statusFilters: string[] = [],
  dateFilter: {
    from?: Date | null;
    to?: Date | null;
  },
): Promise<TPaginatedResponse<TJob>> => {
  const fromParam = Math.floor(Number(dateFilter.from) / 1000) || '';
  const toParam = Math.floor(Number(dateFilter.to) / 1000) || '';
  const { data } =
    (await client.get(
      `/${workspaceId}/jobs?from=${fromParam}&to=${toParam}&pageNo=${page}&pageSize=${pageSize}&orderBy=${orderBy}&orderType=${orderType}&search=${encodeURIComponent(
        search,
      )}&status=${statusFilters.join(',')}`,
    )) || {};

  return {
    data: data.data,
    count: data.count,
  };
};
