import { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate, useParams } from 'react-router';
import styled from 'styled-components';

import { routes } from '@routes';

import { useAuthContext } from '@providers/AuthProvider';

import { PositiveIcon } from '@components/icons/Positive';
import { Button } from '@components/form-elements/buttons/Button';
import { Header } from '@components/Header';
import { SoundWave } from '@components/icons/SoundWave';
import { VsLogo } from '@components/Logo';
import { useInitContext } from '@providers/InitProvider';
import { WorkspacesService } from 'app/API';
import { useAPI } from '@hooks/useAPI';

const StyledPositiveIcon = styled(PositiveIcon)`
  margin: 0 auto 20px;
`;
const SuccessHead = styled.p`
  font-weight: 600;
  color: #858dbd;
  font-size: 18px;
  margin-bottom: 5px;
`;

const SuccessText = styled.p`
  font-size: 18px;
  margin-top: 5px;
`;

const Wrapper = styled.div`
  position: absolute;
  top: 0;
  left: -240px;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  z-index: 10000;
  background: #f4f4f4;

  .header-wrapper {
    width: 100% !important;
  }

  .content {
    width: 540px;
    height: calc(100% - 96px);
    margin: 0 auto;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    grid-gap: 40px;

    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    text-align: center;
    color: #000000;

    h2 {
      font-weight: 600;
      font-size: 18px;
      line-height: 150%;
      text-align: center;
      text-transform: capitalize;
      color: #858dbd;
      margin: 0;
    }

    .button {
      width: 150px;
    }

    .text {
      max-width: 350px;
    }

    .signInButton {
      display: flex;
      align-items: center;

      .signLink {
        cursor: pointer;
        text-decoration: underline;
        font-weight: 500;
        font-size: 16px;
        line-height: 100%;
        color: #858dbd;
      }
    }

    .footer {
      position: absolute;
      bottom: 20px;
      color: #858dbd;
      font-weight: 400;
      font-size: 16px;
      line-height: 130%;
      margin: 0 0 20px;

      a {
        color: #858dbd;
        font-weight: 500;
        font-size: 12px;
        line-height: 130%;
      }
    }

    .positive-icon {
      width: 100px;
      height: 100px;

      path {
        fill: #7cb1fe;
      }
    }

    .positive-icon__external-circle {
      fill-opacity: 0.1;
    }

    .positive-icon__internal-circle {
      fill-opacity: 0.2;
    }
  }
`;

export const AccessRequestPage = () => {
  const { call } = useAPI();
  const navigate = useNavigate();
  const [isSubmitted, setIsSubmitted] = useState(false);
  const { orgSlug, wsSlug } = useParams<{
    orgSlug: string;
    wsSlug: string;
  }>();
  const { me, setWorkspace } = useAuthContext();
  const { logout } = useInitContext();

  const handleSubmit = async () => {
    await call(WorkspacesService.requestWsAccess({ orgSlug: orgSlug ?? '', slug: wsSlug ?? '' }));
    setIsSubmitted(true);
  };

  const handleGoBack = async () => {
    if (!me.organizations[0]) {
      return;
    }
    const orgSlug = me.organizations[0].slug;
    setWorkspace(me.workspaces[0]);
    navigate(routes.dashboard.make(orgSlug));
  };

  return (
    <Wrapper>
      <Helmet>
        <title>Request access - AutoScript</title>
      </Helmet>
      <Header title={<VsLogo />} />
      <div className="content">
        <div className="text">
          {isSubmitted ? (
            <>
              <StyledPositiveIcon className="positive-icon" />
              <SuccessHead>Success</SuccessHead>
              <SuccessText>Your request was successfully submitted.</SuccessText>
            </>
          ) : (
            <p>You don’t have permission to view this workspace. Request access by clicking the button below</p>
          )}
        </div>
        {!isSubmitted ? (
          <Button className="button" onClick={handleSubmit}>
            Request access
          </Button>
        ) : null}
        <SoundWave />

        <div className="signInButton">
          {!isSubmitted ? (
            <>
              <p className="signLink" onClick={logout}>
                Sign in
              </p>
              &nbsp;with another account
            </>
          ) : null}
        </div>

        <div className="signInButton">
          <p className="signLink" onClick={handleGoBack}>
            ← Back to Dashboard
          </p>
        </div>

        <div className="footer">
          <a href="https://voicescript.ai/terms-of-service/" target="_blank" rel="noreferrer">
            Terms of Service
          </a>{' '}
          |{' '}
          <a href="https://voicescript.ai/privacy-policy/" target="_blank" rel="noreferrer">
            Privacy Policy
          </a>
          <p>© 2022 VoiceScript Inc. All Rights Reserved</p>
        </div>
      </div>
    </Wrapper>
  );
};
