import { client } from 'app/http';
import { TAudioFile } from 'app/types/entities/TAudioFile';

type CreateFromRepoAttachmentsParams = {
  files: TAudioFile[];
  jobId: string;
  workspaceId: string;
  repositoryId: string;
};

export const createFromRepoAttachments = async (payload: CreateFromRepoAttachmentsParams) => {
  const response = await client.post(`/media/from-repository`, payload);
  return response.data;
};
