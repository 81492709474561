import styled from 'styled-components';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { Link as RouterLink } from 'react-router-dom';

import { routes } from '@routes';

import { useAuthContext } from '@providers/AuthProvider';
import { getAmericanTime } from '@helpers/getAmericanTimezone';
import { TJob } from 'app/types/entities/TJob';

const StyledLink = styled(RouterLink)`
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  color: #858dbd;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-decoration: underline;

  &:hover {
    color: #40608f;
  }
`;
const Wrapper = styled.div`
  margin: 20px -20px 0 0;
  height: 480px;

  .jobsList-wrapper {
    padding: 0 20px 0 0;
  }

  .jobsList-noJobs {
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 130%;
    color: #858dbd;
    text-align: center;
    padding: 5px 0 10px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .jobsList-item:last-child {
    margin: 0;
    padding: 0 0 5px;
    border-bottom: none;
  }

  .jobsList-item {
    padding: 0 0 15px;
    margin: 0 0 15px;
    border-bottom: 1px solid #d5def2;

    &-workspace-wrapper {
      margin: 4px 0 0;
      height: 22px;
    }

    &-workspace {
      display: inline-block;
      background-color: #e4ebf5;
      border-radius: 5px;
      padding: 4px 6px;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 120%;
      color: #858dbd;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &-deadline {
      margin: 6px 0 0;
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 150%;
      color: #858dbd;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &-assignee {
      margin: 10px 0 0;
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 150%;
      color: #858dbd;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
`;

type TProps = {
  jobs?: TJob[];
};

export const JobsList = ({ jobs = [] }: TProps) => {
  const { organization, me, setWorkspace } = useAuthContext();
  const jobClick = (job: TJob) => {
    const workspace = me.workspaces.find(
      (ws) => ws.organizationId === organization?.id && (job.workspace ?? {}).id === ws.id,
    );
    if (workspace) {
      setWorkspace(workspace);
    }
  };
  return (
    <Wrapper>
      {!jobs.length ? <div className="jobsList-noJobs">No Jobs</div> : null}
      {jobs.length ? (
        <Scrollbars autoHeight autoHeightMin={30} autoHeightMax={320}>
          <div className="jobsList-wrapper">
            {jobs.map((cdj: TJob) => {
              // const assignee = cdj.assignee ? `${cdj.assignee.name} ${cdj.assignee.lastname}` : null;
              const workspace = cdj.workspace ? cdj.workspace.name : null;
              const deadline = cdj.deadline ? `Deadline: ${getAmericanTime(cdj.deadline, false)}` : null;
              return (
                <div className="jobsList-item" key={cdj.id}>
                  <StyledLink
                    onClick={() => jobClick(cdj)}
                    to={routes.jobDetails.make(organization?.slug ?? '', cdj.workspace?.slug ?? '', cdj.id)}
                  >
                    {cdj.name}
                  </StyledLink>
                  {workspace ? (
                    <div className="jobsList-item-workspace-wrapper">
                      <div className="jobsList-item-workspace">{workspace}</div>
                    </div>
                  ) : null}
                  {/* {assignee && <div className="jobsList-item-assignee">{assignee}</div>}*/}
                  {deadline ? <div className="jobsList-item-deadline">{deadline}</div> : null}
                </div>
              );
            })}
          </div>
        </Scrollbars>
      ) : null}
    </Wrapper>
  );
};
