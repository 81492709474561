import React from 'react';

type TProps = {
  children: React.ReactNode;
};

type TState = {
  shouldUpdateUsage: boolean;
  updatePackageUsage: () => void;
};

const PackageUsageContext = React.createContext<TState>({
  shouldUpdateUsage: false,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  updatePackageUsage: () => {},
});

export const PackageUsageProvider = ({ children }: TProps) => {
  const [shouldUpdateUsage, setShouldUpdateUsage] = React.useState(false);
  const updatePackageUsage = () => setShouldUpdateUsage(!shouldUpdateUsage);

  return (
    <PackageUsageContext.Provider
      value={{
        shouldUpdateUsage,
        updatePackageUsage,
      }}
    >
      {children}
    </PackageUsageContext.Provider>
  );
};

export const usePackageUsageContext = () => React.useContext(PackageUsageContext);
