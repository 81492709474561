import styled from 'styled-components';

import { useAuthContext } from '@providers/AuthProvider';

import { Button } from '@components/form-elements/buttons/Button';

const StyledSwitchButtonWrapper = styled.div`
  display: flex;

  .button {
    border-radius: 0;
    background: #f8fafb;
    color: #40608f;
    font-weight: 500;
    font-size: 12px;
    line-height: 130%;
    width: max-content;
    padding: 8px 15px;
  }

  .active {
    background: #d5def2;
  }

  .allJobs {
    border-radius: 5px 0 0 5px;
  }

  .assignToMe {
    border-radius: 0 5px 5px 0;
  }
`;

type TProps = {
  currentTab: string;
  setCurrentTab: (value: 'All Jobs' | 'Assigned To Me') => void;
};

export const TabSwitcher = ({ currentTab, setCurrentTab }: TProps) => {
  const { organization } = useAuthContext();

  if (!['all', 'assignedAndUnassigned'].includes(organization?.permissions?.viewJobs)) {
    return null;
  }
  return (
    <StyledSwitchButtonWrapper key="tabSwitcher">
      <Button
        onClick={() => {
          setCurrentTab('All Jobs');
        }}
        className={`button allJobs ${currentTab === 'All Jobs' ? 'active' : ''}`}
      >
        All Jobs
      </Button>
      <Button
        onClick={() => {
          setCurrentTab('Assigned To Me');
        }}
        className={`button assignToMe ${currentTab === 'Assigned To Me' ? 'active' : ''}`}
      >
        Assigned To Me
      </Button>
    </StyledSwitchButtonWrapper>
  );
};
