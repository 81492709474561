import { useEffect, useState } from 'react';

import { useAuthContext } from '@providers/AuthProvider';
import { getWorkspaceJobs } from '@queries/workspaces/getWorkspaceJobs';
import { getAssignedToMeWorkspaceJobs } from '@queries/workspaces/getAssignedToMeWorkspaceJobs';

import { StatusFilters } from '../components/StatusFilters';
import { TPaginatedResponse } from 'app/types/API/TPaginatedResponse';
import { TJob } from 'app/types/entities/TJob';

type TProps = {
  currentTab: 'All Jobs' | 'Assigned To Me';
  statusFilters: StatusFilters;
  dateFilter: {
    from?: Date | null;
    to?: Date | null;
  };
  page?: number;
  pageSize?: number;
  orderBy?: string;
  orderType?: string;
  search?: string;
  setCheckedJobs: (v: string[]) => void;
};

export const useJobsList = ({
  currentTab,
  statusFilters,
  page = 1,
  pageSize = 25,
  orderBy = 'id',
  orderType = 'asc',
  search = '',
  dateFilter,
  setCheckedJobs,
}: TProps) => {
  const { organization, workspace } = useAuthContext();
  const [jobs, setJobs] = useState<TPaginatedResponse<TJob>>({
    data: [],
    count: 0,
  });
  const [isLoading, setIsLoading] = useState(true);

  const endpoint = currentTab === 'All Jobs' ? getWorkspaceJobs : getAssignedToMeWorkspaceJobs;

  const requestData = async () => {
    // setJobs({ data: [], count: 0 });
    if (!organization || !workspace) {
      return;
    }
    setCheckedJobs([]);
    setIsLoading(true);
    const statusFilterKeys = Object.keys(statusFilters).filter(
      (k) => k !== 'all' && statusFilters[k as keyof typeof statusFilters],
    );
    const data = await endpoint(
      page,
      pageSize,
      orderBy,
      orderType,
      search,
      workspace?.id ?? '',
      statusFilterKeys,
      dateFilter,
    );
    setIsLoading(false);
    setJobs(data);
  };

  useEffect(() => {
    requestData();
  }, [organization, workspace, currentTab, page, pageSize, orderBy, orderType, search, statusFilters, dateFilter]);

  return { isLoading, jobs, setJobs, requestData, endpoint };
};
