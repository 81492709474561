import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

import { routes } from '@routes';

import { useAuthContext } from '@providers/AuthProvider';
import { getMembers } from '@queries/organizations/getMembers';

import { Header } from '@components/Header';
import { Button } from '@components/form-elements/buttons/Button';
import { LinearProgressLoader } from '@components/spinners/LinearProgressLoader';

import { UsersTable } from './UsersTable';
import { getAmericanTime } from '@helpers/getAmericanTimezone';
import { downloadCSV } from '@helpers/downloadCSV';
import { TRole } from 'app/types/entities/TRole';
import { StyledSwitchButtonWrapper } from '@pages/User/MembersPage/styles/StyledSwitchButtonWrapper';
import { PagePagination } from '@components/Pagination';

const MainContent = styled.main`
  padding: 8px 30px 50px;
  min-height: calc(100vh - 120px);

  td {
    vertical-align: top;
  }
`;

export const MembersPage = () => {
  const navigate = useNavigate();

  const { organization } = useAuthContext();

  const [users, setUsers] = useState<any>({});
  const [sortingField, setSortingField] = useState('createdAt');
  const [sortingReverse, setSortingReverse] = useState(true);
  const [search, setSearch] = useState('');
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(25);
  const [isLoading, setIsLoading] = useState(false);

  // const workspaceViewReports = (workspace?.Teams ?? []).find((team) => team.viewReports);
  // const shouldShowReports = !!workspaceViewReports;
  const shouldShowReports = !!organization?.permissions?.viewReports;

  const load = async () => {
    setIsLoading(true);
    const data = await getMembers(1, users.count, 'createdAt', 'desc', search, organization?.id);
    setUsers(data);
    setIsLoading(false);
  };

  useEffect(() => {
    load();
  }, [page, pageSize, search, organization]);

  const onExportCsv = async () => {
    const data = await getMembers(1, users.count, 'createdAt', 'desc', search, organization?.id);
    const headers = ['ID', 'Name', 'Email', 'Phone', 'Enabled', 'Teams', 'Created'];
    const array = data.data.map((data: any) => {
      return {
        id: data.id,
        name: `${data?.name} ${data?.lastname}`,
        email: data.email,
        phone: data.phone,
        enabled: !data.disabledInOrg ? 'Yes' : 'No',
        teams: data.teams
          .filter((team: TRole) => team.organizationId === organization?.id)
          .map((team: TRole) => team.name)
          .join(' '),
        created: getAmericanTime(data.createdAt),
      };
    });
    return downloadCSV(array, headers);
  };

  return (
    <>
      <Helmet>
        <title>Members - AutoScript</title>
      </Helmet>
      <Header
        leftSideContent={[
          <StyledSwitchButtonWrapper key="tabSwitcher">
            <Button
              onClick={() => {
                navigate(routes.organizationMembers.make(organization?.slug ?? ''));
              }}
              className="button active"
            >
              Members
            </Button>
            <Button
              onClick={() => {
                navigate(routes.invitations.make(organization?.slug ?? ''));
              }}
              className="button"
            >
              Invitations
            </Button>
            {shouldShowReports && (
              <Button
                onClick={() => {
                  navigate(routes.organizationMemberReports.make(organization?.slug ?? ''));
                }}
                className="button"
              >
                Reports
              </Button>
            )}
          </StyledSwitchButtonWrapper>,
        ]}
        title="Members"
        buttonLabel={users.data?.length ? 'Export CSV' : undefined}
        buttonOnClick={users.data?.length ? () => onExportCsv() : undefined}
        setSearch={setSearch}
        search={search}
      />

      <LinearProgressLoader active={isLoading} />
      <MainContent>
        <UsersTable
          setIsLoading={setIsLoading}
          users={users}
          setUsers={setUsers}
          reload={load}
          setSortingField={setSortingField}
          setSortingReverse={setSortingReverse}
          sortingField={sortingField}
          sortingReverse={sortingReverse}
        />
        <PagePagination
          totalItemsCount={users.count ?? 0}
          page={page ?? 1}
          setPage={setPage}
          pageSize={pageSize}
          setPageSize={setPageSize}
        />
      </MainContent>
    </>
  );
};
