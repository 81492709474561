import { client } from 'app/http';
import { TUpdateTeamDto } from 'app/types/API/TUpdateTeamDto';

export const updateTeamBySlug = async (slug: string, payload: TUpdateTeamDto) => {
  try {
    const response = await client.patch(`/teams/slug/${slug}`, payload);
    return response.data;
  } catch (err) {
    console.log('updateTeam request failed:', err);
    return [];
  }
};
