import { routes } from '@routes';

import { useAuthContext } from '@providers/AuthProvider';

import { DashboardIcon } from '@components/icons/sidebar/user/Dashboard/icon';
import { UsersIcon } from '@components/icons/sidebar/admin/Users/icon';
import { JobReportsIcon } from '@components/icons/sidebar/admin/JobReports/icon';

import { GlobeIcon } from '@components/icons/sidebar/user/Globe';
import { Folder } from '@components/icons/sidebar/user/Folder';

import { Divider } from '@components/Divider';

import { WorkspacesListWrapper } from './components/WorkspaceListWrapper';

import { checkAssignedRepositories } from '@helpers/checkAssignedRepositories';
import { SidebarLink } from '../SidebarLink';

export const Menu = () => {
  const { me, workspace, organization } = useAuthContext();
  if (!organization) {
    return <></>;
  }

  // const workspaceViewReports = (workspace?.Teams ?? []).find((team) => team.viewReports);
  // const shouldShowReports = !!workspaceViewReports;
  const shouldShowReports = !!organization?.permissions?.viewReports;
  const shouldShowUsers = !!organization?.permissions?.manageMembers;
  const canManageWorkspaces = organization?.permissions?.manageWorkspaces;
  const shouldShowRepositories =
    !!organization?.permissions?.viewRepositories || checkAssignedRepositories(me, organization?.id || '').length > 0;

  return (
    <>
      <SidebarLink
        to={routes.dashboard.make(organization?.slug ?? '')}
        text="Dashboard"
        Icon={DashboardIcon}
        iconOffset={3}
        activeRoutes={[routes.dashboard.make(organization?.slug ?? '')]}
      />

      {shouldShowUsers ? (
        <SidebarLink
          to={routes.organizationMembers.make(organization?.slug ?? '')}
          text="Members"
          Icon={UsersIcon}
          iconOffset={3}
          activeRoutes={[
            routes.organizationMembers.make(organization?.slug ?? ''),
            routes.invitations.make(organization?.slug ?? ''),
            routes.organizationMemberReports.make(organization?.slug ?? ''),
          ]}
        />
      ) : null}

      {canManageWorkspaces ? (
        <SidebarLink
          to={routes.allJobs.make(organization?.slug ?? '')}
          text="All Jobs"
          Icon={GlobeIcon}
          iconOffset={0}
          activeRoutes={[routes.allJobs.make(organization?.slug ?? '')]}
        />
      ) : null}

      <Divider />

      {workspace ? <span className="text-xs text-primary font-medium block pl-2">Workspace</span> : null}

      {workspace ? <WorkspacesListWrapper /> : null}

      {workspace?.id ? (
        <>
          <SidebarLink
            to={routes.jobsList.make(organization?.slug ?? '', workspace?.slug ?? '')}
            text="Job List"
            Icon={JobReportsIcon}
            iconOffset={4}
            activeRoutes={[routes.jobsList.make(organization?.slug ?? '', workspace?.slug ?? '')]}
          />

          {shouldShowReports ? (
            <SidebarLink
              to={routes.jobsReport.make(organization?.slug ?? '', workspace?.slug ?? '')}
              text="Job Reports"
              Icon={JobReportsIcon}
              iconOffset={4}
              activeRoutes={[routes.jobsReport.make(organization?.slug ?? '', workspace?.slug ?? '')]}
            />
          ) : null}
        </>
      ) : null}

      {shouldShowRepositories ? (
        <SidebarLink
          to={routes.repositoriesList.make(organization?.slug ?? '')}
          text="Cases"
          Icon={Folder}
          iconOffset={1}
          activeRoutes={[routes.repositoriesList.make(organization?.slug ?? '')]}
        />
      ) : null}
    </>
  );
};
